import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setDeclineInvitation } from '../redux-toolkit/reducer/connectionReducer';
import { PORTAL_OFFLINE } from '../utils/constants';
import _ from 'lodash';

export default function useAblyDeclineStatus() {
    const dispatch = useDispatch();
    const presenceData = useSelector((state) => state.ably?.presenceData);

    const { declinedInvitationRaw, declinedInvitation } = useSelector(({ connection }) => connection);
    useEffect(() => {
        if (
            declinedInvitationRaw &&
            Object.keys(declinedInvitationRaw)?.length > 0 &&
            presenceData?.length > 0 &&
            !_.isEqual(declinedInvitationRaw, declinedInvitation)
        ) {
            const presencePortal = presenceData.find(
                (_portal) => _portal?.clientId === declinedInvitationRaw?.inviterPortalRaw?.portal_id,
            );
            const presencePortalStatus = presencePortal?.data?.data?.status || presencePortal?.data?.status;
            const data = {
                ...declinedInvitationRaw,
                inviterPortalRaw: {
                    ...declinedInvitationRaw.inviterPortalRaw,
                    connectionStatus: presencePortalStatus ?? PORTAL_OFFLINE,
                },
            };
            dispatch(setDeclineInvitation(data));
        } else {
            if (Object.keys(declinedInvitation)?.length > 0 && !_.isEqual(declinedInvitationRaw, declinedInvitation)) {
                dispatch(setDeclineInvitation({}));
            }
        }
    }, [declinedInvitationRaw, dispatch, presenceData, declinedInvitation]);

    return { presenceData };
}
